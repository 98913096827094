<!--  -->
<template>
  <div class="cell">
    <van-field
      v-model="inputValue"
      v-bind="{
        ...fieldProps,
      }"
      type="textarea"
      autosize
      rows="1"
      @focus="handleFocus"
      maxlength="200"
    >
      <span slot="right-icon" @click="handlClick" style="color: #3c37d2">
        定位
      </span>
    </van-field>
    <van-popup v-model="showPicker" position="bottom" @opened="handleOpened">
      <div class="popup-wrapper">
        <div class="search-wrapper">
          <van-field
            v-model="keyword"
            center
            label="航空港区"
            placeholder="输入关键字检索位置"
            label-width="60"
          >
            <template #button>
              <van-button size="small" type="primary" @click="searchByKeyWord">
                搜索
              </van-button>
            </template>
          </van-field>
        </div>
        <div class="map-wrapper" :id="amapId"></div>
        <div class="poi-wrapper">
          <p class="poi-title">附近位置</p>
          <p class="poi-tips">若无法自动获取位置，可在顶部输入关键字检索</p>
          <div class="poi-list">
            <div
              class="poi-item"
              v-for="(item, i) in poiList"
              :key="i"
              @click="handlePoiSelect(item)"
            >
              <p class="p1">
                <van-icon name="aim" color="#666" />

                {{ item.name }}
              </p>
              <p class="p2">
                {{ item.address }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { guid } from "@/utils";
const amapKey = "f0ad268ea5ce7e3210db733326750359";
export default {
  data() {
    return {
      showPicker: false,
      amap: null,
      placeSearch: null,
      keyword: "",
      poiList: [],
      amapId: guid(10),
      centerMarker: null,
      currentPoi: null,
      isSearchNearByCenter: true, //是否搜索周边
      isloading: false,
    };
  },
  props: {
    options: Array,
    value: String,
    fieldProps: Object,
    pickerProps: Object,
  },
  computed: {
    columns() {
      return this.options.map((el) => el.label);
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  components: {},

  mounted() {},

  methods: {
    handleFocus() {
      if (!this.inputValue) {
        this.showPicker = true;
      }
    },
    handlClick() {
      if (
        this.fieldProps &&
        (this.fieldProps.readonly || this.fieldProps.disabled)
      )
        return;
      this.showPicker = true;
    },
    onConfirm(label) {
      const value = this.options.find((el) => el.label === label).value;
      this.$emit("input", value);
      this.showPicker = false;
    },
    handleOpened() {
      // if (!this.amap) {
      // }
      this.initMap();
    },
    initMap() {
      AMapLoader.load({
        key: amapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        animateEnable: false,
        plugins: ["AMap.PlaceSearch", "AMap.Geolocation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        // AMap.plugin("AMap.PlaceSearch", () => {
        //   this.placeSearch = new AMap.AutoComplete({
        //     city: "郑州市",
        //     datatype: "poi"
        //   });
        // });

        this.amap = new AMap.Map(this.amapId, {
          zoom: 15, //级别
          zooms: [2, 20],
          center: [113.83, 34.53],
          mapStyle: "amap://styles/whitesmoke",
          animateEnable: true, // 地图移动时的动画
          showLabel: true,
          // labelzIndex: 99
        });
        this.amap.on("complete", () => {
          this.getLocation();
          this.addCenterMarker();
          this.placeSearch = new AMap.PlaceSearch({
            city: "新郑市",
            pageSize: 20,
          });
          this.searchByCenter();
          this.amap.on("moveend", (e) => {
            this.updateCenterMarker();
            if (this.isSearchNearByCenter) {
              this.searchByCenter();
            }
          });
        });
      });
    },
    //搜索
    searchByKeyWord() {
      if (!this.placeSearch || !this.keyword) return;
      this.$toast.loading({
        duration: 0,
        message: "检索位置中...",
        forbidClick: true,
      });
      this.placeSearch.search(this.keyword, (status, result) => {
        if (result && result.poiList) {
          this.poiList = result.poiList.pois;
          this.isSearchNearByCenter = false;
          this.amap.setCenter(result.poiList.pois[0].location, true);
          this.$toast.clear();
          setTimeout(() => {
            this.isSearchNearByCenter = true;
          }, 300);
        } else {
          this.$toast.fail({
            message: "未检索到相关位置",
            forbidClick: true,
          });
        }
      });
    },
    //搜索
    searchByCenter() {
      if (!this.placeSearch) return;
      this.placeSearch.searchNearBy(
        "",
        this.amap.getCenter(),
        5000,
        (status, result) => {
          if (result && result.poiList) {
            this.poiList = result.poiList.pois;
          }
        }
      );
    },
    //获取位置
    getLocation() {
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,
        //  定位按钮的排放位置,  RB表示右下
        buttonPosition: "RB",
      });
      geolocation.getCurrentPosition((status, result) => {
        //获取用户当前的精确位置
        if (status == "complete") {
          this.$toast("获取当前位置成功")
          this.amap.setCenter([result.position.lng, result.position.lat]);
          this.searchByCenter();
        } else {
          this.$toast("自动获取位置失败，可在顶部输入关键字搜索");
        }
      });
    },
    handlePoiSelect(poi) {
      this.showPicker = false;
      this.currentPoi = poi;
      this.$emit("poiSelect", poi);
    },
    addCenterMarker() {
      this.marker = new AMap.Marker({
        // icon: ,
        icon: new AMap.Icon({
          image:
            "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          size: new AMap.Size(24, 30),
          imageSize: new AMap.Size(24, 30),
        }),
        position: this.amap.getCenter(),
        offset: new AMap.Pixel(-12, -30),
      });
      // this.marker.setSize([20, 20]);
      this.marker.setMap(this.amap);
    },
    updateCenterMarker() {
      this.marker.setPosition(this.amap.getCenter());
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: relative;
  &:after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
.popup-wrapper {
  height: 90vh;
  display: flex;
  flex-direction: column;
  // .search-wrapper {
  // }

  .map-wrapper {
    background-color: #ddd;
    flex: 1;
  }
  .poi-wrapper {
    flex: 1;
    overflow: auto;
    padding: 0.3rem;
    .poi-title {
      font-size: 0.3rem;
      font-weight: bold;
      color: #666;
      margin-bottom: 0.05rem;
    }
    .poi-tips {
      font-size: 0.15rem;
      color: #ee0a24;
    }
  }
}
.poi-list {
  .poi-item {
    border-bottom: 1px solid #dfdfdf;
    padding: 0.2rem 0;
    .p1 {
      font-size: 0.3rem;
      font-weight: bold;
      line-height: 0.4rem;
    }
    .p2 {
      font-size: 0.28rem;
      line-height: 0.4rem;
      color: #666;
    }
  }
}
</style>
