<template>
  <van-dialog v-model="visible" :show-confirm-button="false" ref="form">
    <van-form
      class="user-form"
      @submit="handleSubmit"
      validate-first
      :show-error="false"
      label-width="70"
      :rules="rules"
    >
      <van-field
        v-model="form.phone"
        name="手机号"
        label="手机号"
        placeholder="请输入手机号"
        :rules="rules.phone"
      >
        <template #button>
          <CodeBtn :phone="form.phone" action="/rent/statistics/sendSMSCode" />
        </template>
      </van-field>
      <van-field
        v-model="form.smsCode"
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="rules.smsCode"
      >
      </van-field>
      <div class="submit-btn">
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          :loading="loading"
        >
          开始申报
        </van-button>
      </div>
    </van-form>
  </van-dialog>
</template>

<script>
import CodeBtn from "@/components/CodeBtn";
export default {
  data() {
    return {
      loading: false,
      form: {
        phone: "",
        smsCode: ""
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号"
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式"
          }
        ],
        smsCode: [
          {
            required: true,
            message: "请输入验证码"
          }
        ]
      }
    };
  },
  components: { CodeBtn },
  props: {
    visible: Boolean
  },

  methods: {
    handleSubmit() {
      this.loading = true;
      this.$store
        .dispatch("houseSurvey/getSmsValid", this.form)
        .then((res) => {
          this.loading = false;
          this.$emit("success", this.form);
        })
        .catch((err) => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff;
  padding: 0.2rem 0.15rem;
}
.title {
  font-size: 0.3rem;
  margin-bottom: 0.2rem;
  text-align: center;
}
.form {
  margin-bottom: 0.5rem;
}
.user-form {
  padding-top: 30px;
  .submit-btn {
    margin: 30px 16px;
  }
}
</style>
