import Cookies from "js-cookie";
const auth_key = "HouseSurveyPhone";
export default {
  data() {
    return {
      showForm: false,
      showAuth: false,
      contactPhone: "",
    };
  },
  async created() {
    const contactPhone = Cookies.get(auth_key) || "";
    if (contactPhone) {
      //查询历史表单
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.contactPhone = contactPhone;
      await this.getDefaultForm();
      this.showForm = true;
      this.$toast.clear();
    } else {
      //验证手机号
      this.showAuth = true;
    }
  },
  methods: {
    async suthSuccess(val) {
      Cookies.set(auth_key, val, { expires: 1 });
      //查询历史表单
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.contactPhone = val;
      await this.getDefaultForm();
      this.$toast.clear();
      this.showAuth = false;
      this.showForm = true;
    },
  },
};
